import React from 'react';
import './MatchList.css';
import './MatchCard.css';
import ComponentBase from './ComponentBase';
import MatchCard from './MatchCard';

class MatchList extends ComponentBase {

    static defaultProps = {
        teamAbbv: null,
        numLastMatches: 3,
        numNextMatches: 3,
        showHeaders: true
    }

    state = {
        lastMatches: [],
        nextMatches: [],
        loading: false
    }

    componentDidMount() {
        if (this.props.numLastMatches && this.props.numNextMatches) {
            this.loadLastAndFutureMatches();
        } else if (this.props.numLastMatches) {
            this.loadLastMatches();
        } else {
            this.loadFutureMatches();
        }
    }

    loadLastAndFutureMatches() {
        this.setState({loading: true})
        const teamUrlParam = this.props.teamAbbv ? `&team=${this.props.teamAbbv}` : '';
        Promise.all([
            fetch(`/api/matches/?last=20${teamUrlParam}`),
            fetch(`/api/matches/?next=5${teamUrlParam}`)
        ]).then(responses => {
            return Promise.all(responses.map(response => response.json()));
        }).then(jsonResponses => {
            const lastMatches = jsonResponses[0]['results'].reverse();
            const nextMatches = jsonResponses[1]['results'];
            this.setState({
                loading: false,
                lastMatches: lastMatches,
                nextMatches: nextMatches
            });
        });
    }

    loadLastMatches() {
        this.setState({loading: true})
        const teamUrlParam = this.props.teamAbbv ? `&team=${this.props.teamAbbv}` : '';
        fetch(`/api/matches/?last=5${teamUrlParam}`)
            .then(resp => resp.json())
            .then(jsonResp => {
                this.setState({
                    loading: false,
                    lastMatches: jsonResp['results'].reverse()
                })
            });
    }

    loadFutureMatches() {
        this.setState({loading: true})
        const teamUrlParam = this.props.teamAbbv ? `&team=${this.props.teamAbbv}` : '';
        fetch(`/api/matches/?next=5${teamUrlParam}`)
            .then(resp => resp.json())
            .then(jsonResp => {
                this.setState({
                    loading: false,
                    nextMatches: jsonResp['results']
                })
            });
    }


    getMatchCard(match) {
        return (<MatchCard home_team={match.home_team} home_score={match.home_score}
                           away_team={match.away_team} away_score={match.away_score} time={match.time}
                           youtube_url={match.youtube_url}
                           league_name={match.season.competition_name}
                           league_hover_text={`${match.season.label} ${match.season.competition_name}`}/>);
    }

    render() {
        return <div>
            {this.state.lastMatches.length > 0 ?
                <div className='match-list'>
                    {this.props.showHeaders && <div className='match-list-header'>Previous Matches</div>}
                    <div>
                        {this.state.lastMatches.map(match => this.getMatchCard(match))}
                    </div>
                </div>
                : null
            }
            {this.state.nextMatches.length > 0 ?
                <div className='match-list'>
                    {this.props.showHeaders && <div className='match-list-header'>Upcoming Matches</div>}
                    <div>
                        {this.state.nextMatches.map(match => this.getMatchCard(match))}
                    </div>
                </div>
                : null
            }
        </div>;
    }

}

export default MatchList;