import React from "react";

const dontBind = [
    'constructor',
    'render',
    'componentWillMount',
    'componentDidMount',
    'componentWillReceiveProps',
    'shouldComponentUpdate',
    'componentWillUpdate',
    'componentDidUpdate',
    'componentWillUnmount'
];


class ComponentBase extends React.Component {
    constructor(props) {
        super(props);

        const prototype = Object.getPrototypeOf(this);
        Object.getOwnPropertyNames(prototype)
            .filter(prop => !dontBind.includes(prop))
            .forEach(prop => this[prop] = this[prop].bind(this));

        if (props.setRef) {
            props.setRef(this);
        }
    }
}

export default ComponentBase;