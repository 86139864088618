import React from 'react';
import './PickLastWatchedModal.css';
import UserState from './UserState';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {formatMonthDay} from './utils';

class PickLastWatchedModal extends React.Component {

    static defaultProps = {
        teamAbbv: null,
        lastWatchTimeSelected: () => {}
    }

    state = {
        teamSlug: this.props.teamSlug,
        matches: {},
        loading: true,
    };

    componentDidMount() {
        this.loadMatches();
    }

    handleMatchSelection = (event) => {
        const mapKey = event.target.value;
        if (mapKey === 'N/A') {
            this.props.lastWatchTimeSelected(this.getDateBeforeSeasonStart());
            return;
        }
        const match = this.state.matches[mapKey];
        const matchTime = new Date(match['time']);
        const matchEndTime = new Date(matchTime.getTime() + (90 * 60 * 1000));
        this.props.lastWatchTimeSelected(matchEndTime);
    };

    loadMatches() {
        this.setState({loading: true})
        const teamSlug = UserState.getTeam().abbv;
        fetch(`/api/matches/?last=20&team=${teamSlug}`)
            .then(resp => resp.json())
            .then(jsonResp => {
                const matches = jsonResp['results'].reverse();
                if (matches.length === 0) {
                    this.props.lastWatchTimeSelected(new Date());
                    return;
                }
                const matchDict = Object.fromEntries(matches.map(m => [matchSlug(m), m]))
                this.setState({
                    loading: false,
                    matches: matchDict
                });
            });
    }

    getDateBeforeSeasonStart() {
        const matches = this.state.matches;
        const firstMatchDate = matches ? new Date(Object.values(matches)[0]['time']) : null;
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        return firstMatchDate ? new Date(firstMatchDate.valueOf() - oneDayInMilliseconds) : null;
    }

    render() {
        const loading = this.state.loading;
        const matches = this.state.matches;
        return (
            <div>
                {loading && <span>loading teams...</span>}
                {!loading && <div>
                    <h4>What was the last game you remember watching?</h4>
                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='matches' value={this.state.teamSlug}
                                    onChange={this.handleMatchSelection}>
                            <FormControlLabel control={<Radio/>}
                                              label={'Haven\'t seen one this season'}
                                              value={'N/A'} key={0}/>
                            {Object.values(matches)
                                .map(match => <FormControlLabel
                                        control={<Radio/>}
                                        label={`
                                    ${formatMonthDay(new Date(match['time']))} - 
                                    ${match["home_team"]["name"]} at ${match["away_team"]["name"]} 
                                    `}
                                        value={matchSlug(match)}
                                        key={matchSlug(match)}
                                    />
                                )}
                        </RadioGroup>
                    </FormControl>
                </div>}
            </div>
        );
    }
}

function matchSlug(match) {
    return `${match["home_team"]["abbv"]}v${match["away_team"]["abbv"]}`;
}

export default PickLastWatchedModal;