import React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

export default class TeamSelector extends React.Component {

    static defaultProps = {
        teamSelected: () => {
        }
    }

    state = {
        teams: {},
        loading: true
    }

    componentDidMount() {
        this.fetchTeams();
    }

    fetchTeams() {
        fetch('/api/competitions/eng.1/teams/')  // TODO league hardcoded
            .then(resp => resp.json())
            .then(
                (teams) => {
                    const teamAbbvMap = Object.fromEntries(teams.map(t => [t['abbv'], t]))
                    this.setState({
                        teams: teamAbbvMap,
                        loading: false
                    })
                }
            )
    }

    teamSelected = (event) => {
        const team = this.state.teams[event.target.value];
        this.props.teamSelected(team);
    }

    render() {
        const loading = this.state.loading;
        return (
            <div>
                {loading && <h3>loading...</h3>}
                {!loading && <div>
                    <h3>select a team</h3>
                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='matches' value={this.state.teamSlug}
                                    onChange={this.teamSelected}>
                            {Object.values(this.state.teams)
                                .map(t =>
                                <FormControlLabel
                                    control={<Radio/>}
                                    label={t['name']}
                                    value={t['abbv']}
                                    key={t['abbv']}
                                />

                            )}
                    </RadioGroup>
                </FormControl>
                    </div>}
            </div>
        );
    }

}