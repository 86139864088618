import './App.css';
import React from "react";
import PickLastWatchedModal from './PickLastWatchedModal';
import TeamSelector from './TeamSelector';
import UserState from './UserState';
import ComponentBase from './ComponentBase';
import {Button} from '@material-ui/core';
import TeamHome from './TeamHome';

class App extends ComponentBase {

    static DisplayStates = {
        LOADING: 1,
        PICK_TEAM: 2,
        PICK_LAST_WATCHED: 3,
        VIEW_MATCHES: 4
    }

    state = {
        team: null,
        lastWatchTime: null,
        displayState: App.DisplayStates.LOADING
    }

    componentDidMount() {
        this.setDisplayState();
    }

    teamSelected(team) {
        UserState.setTeam(team);
        this.setState({team: team})
        this.setDisplayState();
    }

    lastWatchTimeSelected(time) {
        UserState.setLastWatchTime(time);
        this.setState({lastWatchTime: time})
        this.setDisplayState();
    }

    reset() {
        UserState.clearTeam();
        UserState.clearLastWatchTime();
        this.setDisplayState();
    }

    setDisplayState() {
        const team = UserState.getTeam();
        const lastWatchTime = UserState.getLastWatchTime();
        if (!team) {
            this.setState({displayState: App.DisplayStates.PICK_TEAM})
        } else if (!lastWatchTime) {
            this.setState({displayState: App.DisplayStates.PICK_LAST_WATCHED})
        } else {
            this.setState({displayState: App.DisplayStates.VIEW_MATCHES})
        }
    }

    render() {
        const team = UserState.getTeam();
        const teamSlug = team ? team.abbv : null;
        return (
            <div className="App">
                <Button className='reset-button' onClick={this.reset}>start over</Button>
                {this.state.displayState === App.DisplayStates.LOADING && <h1>loading </h1>}
                {this.state.displayState === App.DisplayStates.PICK_TEAM &&
                <TeamSelector teamSelected={this.teamSelected}/>}
                {this.state.displayState === App.DisplayStates.PICK_LAST_WATCHED &&
                <PickLastWatchedModal teamSlug={teamSlug} lastWatchTimeSelected={this.lastWatchTimeSelected}/>}
                {this.state.displayState === App.DisplayStates.VIEW_MATCHES &&
                <div>
                    <TeamHome/>
                </div>}
            </div>
        );
    }
}

export default App;
