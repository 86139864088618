import TeamsModel from './TeamsModel';

let _userStateInstance = null;

class UserState {
    static getInstance() {
        if (!_userStateInstance) {
            _userStateInstance = new UserState();
        }
        return _userStateInstance;
    }

    static setTeam(team) {
        localStorage.setItem('team-slug', team.abbv);
    }

    static getTeam() {
        const teamSlug = localStorage.getItem('team-slug');
        if (!teamSlug) {
            this.clearLastWatchTime();
        }
        return teamSlug ? TeamsModel.getTeam(teamSlug) : null;
    }

    static clearTeam() {
        localStorage.removeItem('team-slug');
    }

    static getLastWatchTime() {
        const lastWatchTimeStr = localStorage.getItem('last-watch-time');
        return lastWatchTimeStr ? new Date(lastWatchTimeStr) : null;
    }

    static setLastWatchTime(date) {
        localStorage.setItem('last-watch-time', date.toISOString());
    }

    static clearLastWatchTime() {
        localStorage.removeItem('last-watch-time');
    }

}

export default UserState;