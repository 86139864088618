import React from 'react';
import UserState from './UserState';
import MatchList from './MatchList';


export default class TeamHome extends React.Component {

    static defaultProps = {
        team: null
    }

    state = {
        matches: [],
        loading: false
    }

    componentDidMount() {
        const team = UserState.getTeam();
        this.setState({team: team})
    }

    loadMatches() {

    }

    render() {
        const team = this.state.team;
        return (
            <div>
                {this.state.loading && <h1>loading...</h1>}
                {!this.state.loading && this.state.team &&
                <div>
                    <h1>{team.name}</h1>
                    <MatchList teamAbbv={team.abbv} />
                </div>}
            </div>
        );
    }
}