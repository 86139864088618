import './MatchCard.css'
import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {formatAMPM} from './utils';
import UserState from './UserState';
import {YouTube} from '@mui/icons-material';

class MatchCard extends React.Component {

    static defaultProps = {
        home_team: {name: null, abbv: null},
        away_team: {name: null, abbv: null},
        time: null,
        home_score: null,
        away_score: null,
        league_name: null,
        league_hover_text: null,
        youtube_url: null
    };

    shouldHideScore() {
        const matchTime = new Date(this.props.time);
        const userTime = UserState.getLastWatchTime();
        return userTime < matchTime;
    }

    matchHasPassed() {
        return this.props.home_score != null && this.props.away_score != null;
    }

    getDateDisplay(dateStr) {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
            'Sept', 'Oct', 'Nov', 'Dec'];
        const date = new Date(dateStr);
        return `${monthNames[date.getMonth()]} ${date.getDate()}`
    }

    getDateHoverText(dateStr) {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'];
        const date = new Date(dateStr);
        return `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${formatAMPM(date)}`
    }

    getBannerText() {
        if (this.matchHasPassed() && this.shouldHideScore()) {
            return 'HIDDEN';
        } else if (!this.matchHasPassed()) {
            return 'UPCOMING';
        }
    }

    render() {
        const homeName = this.props.home_team.name;
        const homeScore = this.props.home_score;
        const awayName = this.props.away_team.name;
        const awayScore = this.props.away_score;
        const matchTimeStr = this.props.time;
        const leagueName = this.props.league_name;
        const leagueHoverText = this.props.league_hover_text;
        const youtubeLink = this.props.youtube_url;
        const bannerText = this.getBannerText();

        return (
            <Paper className={'match-card col-xs-12 col-sm-6 col-md-6'}>
                {bannerText && <div className={'card-banner' + (this.matchHasPassed() ? '': ' upcoming')}>{bannerText}</div>}
                <div className={'name-score-row away-team-row'}>
                    <span>{awayName}</span>
                    <span>{this.shouldHideScore() && this.matchHasPassed() ? '?' : awayScore}</span>
                </div>
                <div className={'name-score-row home-team-row'}>
                    <span>{homeName}</span>
                    <span>{this.shouldHideScore() && this.matchHasPassed() ? '?' : homeScore}</span>
                </div>
                <div className={'match-card-footer'}>
                    <Tooltip className={'match-time'}
                             title={this.getDateHoverText(matchTimeStr)}>
                    <span className={'match-time'}
                          aria-label={this.getDateHoverText(matchTimeStr)}>
                        {this.getDateDisplay(matchTimeStr)}</span>
                    </Tooltip>
                    {youtubeLink && <a className={'youtube-link'} href={youtubeLink} target='_blank'><YouTube/></a>}
                    <Tooltip className={'league-name'}
                             title={leagueHoverText}>
                        <span className={'league-name'}>{leagueName}</span>
                    </Tooltip>
                </div>
            </Paper>
        );
    }
}

export default MatchCard;