let _teamsInstance = null;

class TeamsModel {
    constructor() {
        this.prefetched = false;

        // The cache map: slug -> Team
        this.teams = {};

        this.prefetchTeams();
    }

    static getInstance() {
        if (!_teamsInstance) {
            _teamsInstance = new TeamsModel();
        }
        return _teamsInstance;
    }

    prefetchTeams() {
        if (this.prefetched) {
            return;
        }

        fetch('/api/competitions/eng.1/teams/')  // TODO league hardcoded
            .then(resp => resp.json())
            .then(
                (teams) => {
                    teams.forEach(team => TeamsModel.insertTeam(team));
                    this.prefetched = true;
                }
            )
    }

    _insertTeam(team) {
        localStorage.setItem(team.abbv.toLowerCase(), JSON.stringify(team));
    }

    static insertTeam(team) {
        return TeamsModel.getInstance()._insertTeam(team);
    }

    _getTeam(abbv) {
        const lsResult = localStorage.getItem(abbv.toLowerCase());
        return lsResult ? JSON.parse(lsResult) : null;
    }

    static getTeam(abbv) {
        return TeamsModel.getInstance()._getTeam(abbv);
    }

}

export default TeamsModel;