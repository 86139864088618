export function formatAMPM(date) {
    /**
     * https://stackoverflow.com/a/8888498/1446945
     * @type {number | any}
     */
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
}

export function formatMonthDay(date) {
    let monthInt = date.getMonth();
    let dayInt = date.getDate();
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${monthNames[monthInt]} ${dayInt}`;
}